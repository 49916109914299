import React from 'react'
import "./sections.css"
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image';
import { SHOP_URL } from '../../constants/config';
const Section1 = () => {
    const buttons = [
        { className:"foster",link: '/foster', label: "Foster" },
        { className:"volunteer",link: '/volunteer', label: "Volunteer" },
        { className:"adoption",link: '/adoption', label: "Adopt" },
        { className:"donate",link: '/donate', label: "Donate" },
        { className:"shop",link: SHOP_URL, label: "Shop",target:"_blank" },
    ];



    return <section className="sec1">
        <div className="2xl:container flex w-full mx-auto justify-end px-4">
            <div className="content">
                <h2>Even Labrador Retrievers, America’s most popular dogs, need a little help sometimes.</h2>
                <p>Partner with the <strong>Dallas Fort Worth Labrador Retriever Rescue Club</strong> and be a hero to a deserving Lab by <strong>fostering</strong>, <strong>volunteering</strong>, or <strong>adopting</strong>.</p>
                <p>Make a difference in a Lab’s life and watch them change yours.</p>
                <div className="buttons">
                    {buttons && buttons.map((item, key) => {
                        return <Link target={item.target?item.target:"_self"} key={key} className={`${item.className}`} to={`${item.link}`}>{item.label}</Link>
                    })}
                </div>
            </div>
        </div>
    </section>
}

const Section2 = () => {
    return <section className="sec2">
        <div className="inner-cont">
            <div className="content">
                <h3 className="ybar">Our Mission</h3>
                <p>By rescuing and rehabilitating homeless Labradors in our community, vigorously promoting responsible dog ownership through active participation in pet-related events, and through our own education program, we strive for a better life for each of our animals.</p>
                <p>We have events almost every week all over the DFW area so our labs can find their forever home. Our event calendar always has the most up to date information for local Meet ’n Greet and special events. Come out today, be a hero to a deserving lab, and find your best friend!</p>
                <p>The <strong>Dallas Fort Worth Labrador Retriever Rescue Club, Inc.</strong> <strong>(DFWLR-RC)</strong> is dedicated to alleviating the needless death and suffering of home-less or misplaced (lost and stray) purebred Labrador Retrievers in the Dallas Fort Worth area.</p>
                <Link to="/available-dogs/" className="btn-primary">View Adoptable dogs</Link>
            </div>
            <div className="dog-frame">
                <StaticImage alt="Our Mission" quality={100} src="../../images/our-mission.png" />
            </div>
        </div>
    </section>
}
const Section3 = () => {
    return <section className="sec3">
        <div className="boxes">
            <div className="box-l">
                <span><StaticImage layout="fullWidth" alt="dfw 1" className="img" src="../../images/get-involved-img-1.jpg" /></span>
                <span><StaticImage layout="fullWidth" alt="dfw 2" className="img" src="../../images/get-involved-img-2.jpg" /></span>
                <span><StaticImage layout="fullWidth" alt="dfw 3" className="img" src="../../images/get-involved-img-3.jpg" /></span>
                <span><StaticImage layout="fullWidth" alt="dfw 4" className="img" src="../../images/get-involved-img-4.jpg" /></span>
            </div>
            <div className="box-r">
                <h3>Get Involved</h3>
                <p>We are active in the DFW community and participate in fundraising and community outreach events, as well as Meet &amp; Greets, therefore, not all events will feature adoptable dogs. DFW Lab Rescue alumni are always welcome at our Meet &amp; Greets and select other events. Please check our Event Calendar for details.</p>
                <Link to="https://calendar.google.com/calendar/u/0/embed?src=dfwlabrescuecalendar@gmail.com&amp;ctz=America/Chicago" target={"_blank"} className="btn-primary">View upcoming events</Link>
            </div>
        </div>
    </section>
}
const Section4 = () => {
    return <section className="sec4 flex flex-col justify-center">
        <div className="2xl:container mx-auto py-4 px-4">
            <div className="content">
                <h3 className="ybar">Because our rescue is a 100% volunteer organization, over 97 cents of every dollar goes to the care of our rescued dogs!</h3>
                <p>DFW Lab rescue is proud of our stewardship of the trust our donors and volunteers have in us. Rescued dogs are often not in the best of shape. Some will take a few weeks to recover and some will take months. Our donors allow us to provide excellent care and rescue dogs that otherwise would never receive that chance.</p>
                <Link to="/stories/" className="btn-primary">Success Stories</Link>
            </div>
        </div>
    </section>
}

const Sections = {
    Section1,
    Section2,
    Section3,
    Section4
}
export default Sections 