import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HomeBanner from "../components/home/home-banner"
import Sections from "../components/home/sections"
import HowToHelp from "../components/shared/how-to-help"

const IndexPage = () => {


  return <Layout>
    <Seo title="Home" />
    <HomeBanner />
    <Sections.Section1 />
    <Sections.Section2 />
    <Sections.Section3 />
    <Sections.Section4 />
    <HowToHelp />
  </Layout>
}

export default IndexPage
