import  React from "react";
import "./home-banner.css"
import IconLink from '../shared/icon-link'
const HomeBanner = () => {


    return <div className="home-banner">
        <div className="content">
            <h4>DFW <span className="gray1">LABRADOR RETRIEVER</span> <span className="gray2">RESCUE CLUB</span></h4>
            <h1>Be a Hero</h1>
           <IconLink/>
        </div>
    </div>
}

export default HomeBanner